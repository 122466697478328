@import './typography.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'PP Neue Montreal';
    src: local('PP Neue Montreal'),
      url('/fonts/PPNeueMontreal-Regular.woff') format('woff'),
      url('/fonts/PPNeueMontreal-Regular.woff2') format('woff2'),
      url('/fonts/PPNeueMontreal-Regular.otf') format('otf'),
      url('/fonts/PPNeueMontreal-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'PP Neue Montreal';
    src: local('PP Neue Montreal Bold'),
      url('/fonts/PPNeueMontreal-Bold.woff') format('woff'),
      url('/fonts/PPNeueMontreal-Bold.woff2') format('woff2'),
      url('/fonts/PPNeueMontreal-Bold.otf') format('otf'),
      url('/fonts/PPNeueMontreal-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
}

:root {
  --backgroundColor: #f5f5f0;
  --textColor: #161615;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
main {
  @apply overflow-hidden;
}

svg {
  @apply overflow-visible w-fit;
}

main:not(.start) > div:nth-child(2) {
  @apply rounded-t-3xl overflow-hidden;
}
main > div:nth-child(2) > div {
  @apply pt-20;
}

main > div:not(#hero) {
  @apply z-10 relative;
}
body {
  background: var(--backgroundColor);
  @apply text-base text-BW-black;
}

/* The hack for iOS Safari address bar */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

.text-md,
.text-lg,
.text-xl,
.text-huge {
  @apply font-bold max-w-full;
}

.grid-base {
  @apply grid grid-cols-6 lg:grid-cols-12 gap-x-2 md:gap-x-3 lg:gap-x-6;
}
